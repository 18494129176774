.navbar {
  padding: 20px 50px;
  width: 100%;
  background-color: #001219 !important;
  z-index: 4;
}

.container-fuid {
  height: 100px;
  background-color: #001219;
}

.navbar-dark .navbar-toggler {
  color: #e9d8a6;
}

.custom-toggler.navbar-toggler {
  border-color: #e9d8a6;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(233 216 166)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

/* Screen width 500px max */
@media screen and (max-width: 500px) {
  .navbar {
    padding: 20px 20px !important;
  }
}

/* Screen width 300px max */
@media screen and (max-width: 375px) {
  .navbar {
    padding: 20px 20px !important;
  }
}
