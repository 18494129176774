.project-detail {
  margin: 0px 0px 100px;
  width: 100%;
}

.project-description {
  padding: 25px;
  background-color: #112240;
  box-shadow: 15px 18px 30px -20px rgba(66, 121, 204, 0.7);
  border-radius: 4px;
}

.featured-title {
  font-size: 13px;
  color: #e9d8a6;
  margin: 10px 0px;
}

.project-name {
  margin: 0px 0px 20px;
}

.project-name a {
  font-size: 28px;
  text-decoration: none;
}

.tech-list {
  display: flex;
  margin: 25px 0px 10px;
  padding: 0px;
  list-style: none;
}

.tech-list li {
  margin: 0px 20px 5px 0px;
}

.project-link {
  margin-left: -10px;
}

.project-link a {
  margin: 10px;
  padding: 12px 16px;
}

.project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 15px 18px 30px -20px #ddb892;
}

/* Screen width 768px max */
@media screen and (max-width: 768px) {
  .project-link {
    margin-top: 30px;
    text-align: center !important;
  }

  .project-image {
    margin-bottom: 30px;
    width: 700px !important;
    height: 300px !important;
  }
}

/* Screen width 500px max */
@media screen and (max-width: 500px) {
  .project-image {
    margin-bottom: 30px;
    width: 700px !important;
    height: 200px !important;
  }

  .project-description {
    padding: 20px !important;
  }
}

/* Screen width 375px max */
@media screen and (max-width: 375px) {
  .project-description {
    padding: 15px !important;
  }

  .project-description p {
    margin: 0px !important;
  }

  .tech-list li {
    margin: 0px 15px 5px 0px !important;
  }
}
